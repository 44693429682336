import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';

import { BannerTriggeredData } from 'src/components/EmployerSearch/ContactFeedbackBanner/types';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

interface UseBannerPropsParams {
    resumeHash: string;
    topicId?: string;
    vacancyId?: number;
    shouldShowBanner?: boolean;
    isAllowedPage?: boolean;
}

export const useBannerProps = (params: UseBannerPropsParams): [boolean, (() => void) | undefined] => {
    const { resumeHash, topicId, vacancyId, shouldShowBanner, isAllowedPage } = params;
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;
    const allowedTriggerBanner = isEmployer && !!isAllowedPage;
    const shouldRenderBanner = !!shouldShowBanner && allowedTriggerBanner;

    const handleContactClick = useCallback(() => {
        const params: BannerTriggeredData = {
            resumeHash,
            topicId: Number(topicId) || undefined,
            vacancyId,
        };
        Analytics.sendHHEvent('activityBannerTriggeredByChat', params);
    }, [resumeHash, topicId, vacancyId]);

    return [shouldRenderBanner, allowedTriggerBanner ? handleContactClick : undefined];
};
