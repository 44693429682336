import NotificationTemplate, { Kind } from 'src/components/ResumeView/NotificationTemplate';

export const CONTACT_INFORMATION_VIEW_ERROR_NOTIFICATION_DELAY = 15000;
export const CONTACT_INFORMATION_VIEW_INFO_NOTIFICATION_DELAY = 30000;

export const contactInformationViewErrorMessage = {
    Element: (): JSX.Element => <NotificationTemplate kind={Kind.ContactInformationViewGeneric} hasHeading />,
    kind: 'error',
    autoClose: true,
    autoCloseDelay: CONTACT_INFORMATION_VIEW_ERROR_NOTIFICATION_DELAY,
};
export const CONTACT_INFORMATION_VIEW_ERROR_MESSAGE = 'CONTACT_INFORMATION_VIEW_ERROR_MESSAGE';

export const contactInformationViewInfoMessage = {
    Element: (): JSX.Element => (
        <NotificationTemplate
            kind={Kind.ContactInformationViewInfo}
            hasHeading
            hasFooter
            footerLink="/price/dbaccess"
        />
    ),
    autoClose: true,
    autoCloseDelay: CONTACT_INFORMATION_VIEW_INFO_NOTIFICATION_DELAY,
};
export const CONTACT_INFORMATION_VIEW_INFO_MESSAGE = 'CONTACT_INFORMATION_VIEW_INFO_MESSAGE';

export const contactInformationViewQuotaExceeded = {
    Element: (): JSX.Element => <NotificationTemplate kind={Kind.QuotaExceeded} hasHeading />,
    autoClose: true,
    autoCloseDelay: CONTACT_INFORMATION_VIEW_ERROR_NOTIFICATION_DELAY,
};
export const CONTACT_INFORMATION_VIEW_QUOTA_EXCEEDED = 'CONTACT_INFORMATION_VIEW_QUOTA_EXCEEDED';

export const contactInformationViewResumeCountryMismatch = {
    Element: (): JSX.Element => <NotificationTemplate kind={Kind.ResumeCountryMismatch} hasHeading />,
    kind: 'error',
    autoClose: true,
    autoCloseDelay: CONTACT_INFORMATION_VIEW_ERROR_NOTIFICATION_DELAY,
};
export const CONTACT_INFORMATION_VIEW_RESUME_COUNTRY_MISMATCH = 'CONTACT_INFORMATION_VIEW_RESUME_COUNTRY_MISMATCH';

export const contactInformationViewResumeContentIsTooLarge = {
    Element: (): JSX.Element => <NotificationTemplate kind={Kind.ResumeContentIsTooLarge} />,
    kind: 'error',
    autoClose: true,
    autoCloseDelay: CONTACT_INFORMATION_VIEW_ERROR_NOTIFICATION_DELAY,
};
export const CONTACT_INFORMATION_VIEW_RESUME_CONTENT_IS_TOO_LARGE =
    'CONTACT_INFORMATION_VIEW_RESUME_CONTENT_IS_TOO_LARGE';

export const resumeViewModerationCorrectedMessage = {
    Element: (): JSX.Element => <NotificationTemplate kind={Kind.ResumeModerationCorrected} />,
    kind: 'ok',
    autoClose: true,
};
export const RESUME_VIEW_MODERATION_CORRECTED_MESSAGE = 'RESUME_VIEW_MODERATION_CORRECTED_MESSAGE';
