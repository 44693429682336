import { useRef, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import Gap from 'bloko/blocks/gap';
import { ModalHeader, ModalTitle, ModalContent, ModalFooter } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './index.less';

interface EmptyVacancyProps {
    onClose: () => void;
}

const TrlKeys = {
    title: 'resume.online.interview.invite.title',
    empty: 'resume.online.interview.invite.vacancy.empty',
    create: 'negotiations.changeTopic.createVacancy',
    cancel: 'Cancel',
};

const EmptyVacancy: TranslatedComponent<EmptyVacancyProps> = ({ onClose, trls }) => {
    const videoCallEmptyVacancyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!videoCallEmptyVacancyRef.current) {
            return;
        }
        Analytics.sendHHEventElementShown(videoCallEmptyVacancyRef.current, {
            name: 'video_call_search_empty_vacancy',
        });
    }, []);
    return (
        <div className={styles.layout} ref={videoCallEmptyVacancyRef}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <Gap bottom>{trls[TrlKeys.empty]}</Gap>
            </ModalContent>
            <ModalFooter>
                <Button appearance={ButtonAppearance.Outlined} onClick={onClose}>
                    {trls[TrlKeys.cancel]}
                </Button>
                <FormSpacer>
                    <Button Element={SPALink} kind={ButtonKind.Primary} to="/employer/vacancy/create">
                        {trls[TrlKeys.create]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </div>
    );
};

export default translation(EmptyVacancy);
