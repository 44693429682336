import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { State } from 'src/models/assessments';

const TrlKeys = {
    [State.Revoked]: 'assessment.revoke.error.detail.revoked',
    [State.Deadline]: 'assessment.revoke.error.detail.deadline',
    [State.Started]: 'assessment.revoke.error.detail.started',
    internalServerError: 'assessment.revoke.error.detail.internal',
};

interface Props {
    assessmentState?: State;
    responseStatus?: number;
}

const RevokeAssessmentError: TranslatedComponent<Props> = ({ assessmentState, responseStatus, trls }) => {
    if (responseStatus !== 409) {
        return <>{trls[TrlKeys.internalServerError]}</>;
    }
    if ([State.Revoked, State.Deadline].includes(assessmentState)) {
        return <>{trls[TrlKeys[assessmentState as keyof typeof TrlKeys]]}</>;
    }
    return <>{trls[TrlKeys[State.Started]]}</>;
};

export default {
    Element: translation(RevokeAssessmentError),
    kind: 'error',
};
