import { FC } from 'react';

import { Text, Card } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { MenuItem } from 'bloko/blocks/drop';

import { NegotiationLink } from 'src/models/employerNegotiations/negotiationLinks';
import { StateId } from 'src/models/employerNegotiations/stateId.types';

export type SendAnalyticsHandler = (state: StateId) => void;

interface ChangeTopicMenuItemProps {
    state: NegotiationLink['state'];
    name: string;
    url: NegotiationLink['url'];
    sendAnalytics?: SendAnalyticsHandler;
    isMagritte?: boolean;
}

const ChangeTopicMenuItem: FC<ChangeTopicMenuItemProps> = ({ state, name, url, sendAnalytics, isMagritte }) => {
    const dataQa = `change_topic_menu_item__${state}`;
    const isButton = typeof url === 'function';

    const handleClick = () => {
        sendAnalytics?.(state);
        if (isButton) {
            url();
        }
    };

    const buttonProps = {
        onClick: handleClick,
    };

    const linkProps = {
        Element: SPALink,
        to: url,
        onClick: handleClick,
    };

    if (isMagritte) {
        return (
            <Card
                {...(isButton ? buttonProps : linkProps)}
                borderRadius={16}
                padding={12}
                paddingLeft={16}
                hoverStyle="secondary"
                stretched
                data-qa={dataQa}
            >
                <Text style="primary" typography="label-2-regular">
                    {name}
                </Text>
            </Card>
        );
    }

    return (
        <MenuItem {...(isButton ? buttonProps : linkProps)} data-qa={dataQa}>
            {name}
        </MenuItem>
    );
};

export default ChangeTopicMenuItem;
