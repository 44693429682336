import { Form as FinalForm } from 'react-final-form';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { formMutators } from 'src/utils/forms/mutators';

import ResumeComplaintForm from 'src/components/ResumeComplaint/ResumeComplaintForm';
import { FieldName, FormValues } from 'src/components/ResumeComplaint/ResumeComplaintForm/types';
import validator from 'src/components/ResumeComplaint/ResumeComplaintForm/validator';

const TrlKeys = {
    title: 'employer.vacancyResponses.responseComplaint',
    cancel: 'Cancel',
    submit: 'employer.vacancyResponses.complaintReason.submit',
};

const FORM_ID = 'resume-complaint-form';

const INITIAL_VALUES: FormValues = {
    [FieldName.AddToBlackList]: true,
};

export interface ResumeComplaintProps {
    isOpen: boolean;
    isHidden: boolean;
    onClose: () => void;
    onSubmit: (values: FormValues) => void;
}

const ResumeComplaint: TranslatedComponent<ResumeComplaintProps> = ({ trls, isOpen, isHidden, onClose, onSubmit }) => {
    const { isMobile } = useBreakpoint();
    const title = <span data-qa="resume-complaint-form__header">{trls[TrlKeys.title]}</span>;

    if (isHidden) {
        return null;
    }

    return (
        <FinalForm<FormValues>
            onSubmit={onSubmit}
            mutators={formMutators}
            validate={validator}
            initialValues={INITIAL_VALUES}
            render={({ form, handleSubmit, invalid, submitting }) => {
                const handleClose = () => {
                    form.reset();
                    onClose();
                };

                return isMobile ? (
                    <BottomSheet
                        data-qa="resume-complaint-active-dropdown"
                        header={
                            <NavigationBar
                                title={title}
                                right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                                showDivider="always"
                            />
                        }
                        footer={
                            <BottomSheetFooter>
                                <Button
                                    data-qa="resume-complaint-form__submit"
                                    form={FORM_ID}
                                    type="submit"
                                    mode="primary"
                                    style="accent"
                                    loading={submitting}
                                    disabled={invalid}
                                    stretched
                                >
                                    {trls[TrlKeys.submit]}
                                </Button>
                                <Button
                                    data-qa="resume-complaint-form__close"
                                    mode="secondary"
                                    style="accent"
                                    onClick={handleClose}
                                    disabled={submitting}
                                    stretched
                                >
                                    {trls[TrlKeys.cancel]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        visible={isOpen}
                        onClose={handleClose}
                    >
                        <ResumeComplaintForm id={FORM_ID} onSubmit={handleSubmit} />
                    </BottomSheet>
                ) : (
                    <Modal
                        data-qa="resume-complaint-active-dropdown"
                        title={title}
                        footer={
                            <ActionBar
                                type="modal"
                                primaryActions={
                                    <Button
                                        data-qa="resume-complaint-form__submit"
                                        form={FORM_ID}
                                        type="submit"
                                        mode="primary"
                                        style="accent"
                                        loading={submitting}
                                        disabled={invalid}
                                    >
                                        {trls[TrlKeys.submit]}
                                    </Button>
                                }
                                secondaryActions={
                                    <Button
                                        data-qa="resume-complaint-form__close"
                                        mode="secondary"
                                        style="accent"
                                        onClick={handleClose}
                                        disabled={submitting}
                                    >
                                        {trls[TrlKeys.cancel]}
                                    </Button>
                                }
                            />
                        }
                        size="small"
                        visible={isOpen}
                        onClose={handleClose}
                        actions={<Action mode="secondary" onClick={handleClose} icon={CrossOutlinedSize24} />}
                    >
                        <ResumeComplaintForm id={FORM_ID} onSubmit={handleSubmit} />
                    </Modal>
                );
            }}
        />
    );
};

export default translation(ResumeComplaint);
