import { createElement, useCallback, useContext } from 'react';

import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { useReplace } from '@hh.ru/redux-spa-middleware';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';
import urlParser from 'bloko/common/urlParser';

import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { Lang } from 'src/models/langs';

export enum HiringManagerNotificationType {
    ResumeShared = 'resumeShared',
    VacancyShared = 'vacancyShared',
    HiringManagerCreated = 'hiringManagerCreated',
    HiringManagerCreatedWithVacancy = 'hiringManagerCreatedWithVacancy',
    ResumeLinkCopied = 'resumeLinkCopied',
    ResumeLinkCopyFailed = 'resumeLinkCopyFailed',
    DefaultError = 'defaultError',
}

const TrlKeys = {
    // @todo: Всё что касается HiringManagerCreated должно уехать на бэк нотификации в https://jira.hh.ru/browse/HH-226600
    [HiringManagerNotificationType.DefaultError]: 'employer.hiringManager.defaultError',
    [HiringManagerNotificationType.HiringManagerCreated]: 'employer.notification.hiringManagerCreated',
    [HiringManagerNotificationType.HiringManagerCreatedWithVacancy]:
        'employer.notification.hiringManagerCreatedWithVacancy',
    [HiringManagerNotificationType.ResumeShared]: 'employer.notification.resumeShared',
    [HiringManagerNotificationType.VacancyShared]: 'employer.notification.vacancyShared',
    [HiringManagerNotificationType.ResumeLinkCopied]: 'employer.notification.resumeLinkCopied',
    [HiringManagerNotificationType.ResumeLinkCopyFailed]: 'employer.notification.resumeLinkCopyFailed',
    vacanciesShared: 'employer.notification.vacanciesShared',
    hiringManagerCreatedWithVacancies: 'employer.notification.hiringManagerCreatedWithVacancies',
};

export const useHiringManagerNotification = (): ((
    vacancyName?: string[],
    actionType?: HiringManagerNotificationType
) => void) => {
    const trl = useSelector((state) => state.trl);
    const lang = useContext(TranslationLangContext);
    const trls = trl[lang as Lang];
    const isHiringManagerExperiment = useSelectorNonNullable((state) => state.isHiringManagerExperiment);
    const location = useSelectorNonNullable((state) => state.router.location);
    const { addNotification } = useNotification();
    const replace = useReplace();
    const {
        pathname,
        search,
        query: { action },
    } = location;

    return useCallback(
        (vacancyName?: string[], actionType?: HiringManagerNotificationType) => {
            if (!isHiringManagerExperiment || !trls) {
                return;
            }

            const type = actionType || (action as HiringManagerNotificationType);
            let key;
            switch (type) {
                case HiringManagerNotificationType.DefaultError:
                case HiringManagerNotificationType.ResumeLinkCopyFailed:
                case HiringManagerNotificationType.ResumeLinkCopied:
                case HiringManagerNotificationType.ResumeShared:
                case HiringManagerNotificationType.HiringManagerCreated: {
                    key = trls[TrlKeys[type as keyof typeof TrlKeys]];
                    break;
                }
                case HiringManagerNotificationType.VacancyShared:
                case HiringManagerNotificationType.HiringManagerCreatedWithVacancy: {
                    const isMultiVacancyShare = !!vacancyName && vacancyName.length > 1;
                    key = isMultiVacancyShare
                        ? trls[
                              TrlKeys[
                                  type === 'vacancyShared' ? 'vacanciesShared' : 'hiringManagerCreatedWithVacancies'
                              ]
                          ]
                        : format(trls[TrlKeys[type as keyof typeof TrlKeys]], { '{0}': vacancyName?.[0] || '' });
                    break;
                }
            }
            if (key) {
                const isError =
                    type === HiringManagerNotificationType.ResumeLinkCopyFailed ||
                    type === HiringManagerNotificationType.DefaultError;
                if (!isError) {
                    const newUrl = urlParser(pathname + search);
                    delete newUrl.params.action;
                    replace(newUrl.href, undefined, { cancelFetchingData: true });
                }

                addNotification(Notification, {
                    props: {
                        children: key,
                        addon: createElement(!isError ? CheckCircleFilledSize24 : MinusCircleFilledSize24, {
                            initialColor: !isError ? 'positive' : 'negative',
                        }),
                    },
                });
            }
        },
        [isHiringManagerExperiment, trls, action, addNotification, pathname, search, replace]
    );
};
