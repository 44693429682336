import { FC, ComponentProps } from 'react';

import { VSpacing as MagritteVSpacing, HSpacing as MagritteHSpacing } from '@hh.ru/magritte-ui';
import BlokoHSpasing, { HSpacingBaseProps } from 'bloko/blocks/hSpacing';
import BlokoVSpacing, { VSpacingBaseProps, SpacingMultipliers } from 'bloko/blocks/vSpacing';

import { useMagritte as useRedesign } from 'src/hooks/useMagritte';

type MagritteVSpacingProps = ComponentProps<typeof MagritteVSpacing>;
type MagritteHSpacingProps = ComponentProps<typeof MagritteHSpacing>;

const getBlokoVSpacingProps = (props: MagritteVSpacingProps): VSpacingBaseProps | HSpacingBaseProps => {
    return { base: (props.default / 4) as SpacingMultipliers };
};

export const VSpacing: FC<MagritteVSpacingProps> = (props) => {
    const isMagritte = useRedesign();

    if (isMagritte) {
        return <MagritteVSpacing {...props} />;
    }

    return <BlokoVSpacing {...getBlokoVSpacingProps(props)} />;
};

export const HSpacing: FC<MagritteHSpacingProps> = (props) => {
    const isMagritte = useRedesign();

    if (isMagritte) {
        return <MagritteHSpacing {...props} />;
    }

    return <BlokoHSpasing {...getBlokoVSpacingProps(props)} />;
};
