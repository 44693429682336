import { FC } from 'react';

import { Card, Cell, CellText } from '@hh.ru/magritte-ui';

import FieldWrappedSwitch from 'src/components/HiringManager/VacancyPermissions/VacancyPermission/FieldWrappedSwitch';

const CARD_PROPS = {
    borderWidth: 'default',
    stretched: true,
    borderRadius: 16,
    padding: 16,
} as const;

interface VacancyPermissionProps {
    name: string;
    trl: string;
    descriptionTrl?: string;
    disabled?: boolean;
}

const VacancyPermission: FC<VacancyPermissionProps> = ({ name, trl, descriptionTrl, disabled }) => (
    <Card {...CARD_PROPS}>
        <Cell right={<FieldWrappedSwitch name={name} />} disabled={disabled}>
            <CellText type="title">{trl}</CellText>
            {descriptionTrl && <CellText type="subtitle">{descriptionTrl}</CellText>}
        </Cell>
    </Card>
);

export default VacancyPermission;
