import { Fragment, useCallback, memo, ComponentType } from 'react';

import { Link, VSpacing } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { ResumeComment, Status } from 'src/models/employer/resume/resumeComments';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import Item, { CommentProps } from 'src/components/ResumeComments/Item';
import { CommentsOwner } from 'src/components/ResumeComments/types';

const TrlKeys = {
    showMore: 'resume.comments.more',
    one: 'employer.resume.comment.1',
    two: 'employer.resume.comment.2',
    many: 'employer.resume.comment.5',
};

interface ListProps {
    comments: {
        status: string;
        total: number;
        items: ResumeComment[];
        maxOffset: number;
        timeDiff?: number;
    };
    user: CommentsOwner;
    onEdit: (data: ResumeComment) => void;
    editId: string | number | null;
    setEditId: (commentId: number | null) => void;
    fetchingIds: number[];
    commentAction: (id: number, action: string) => void;
    fetch: (offset: number, signal?: AbortSignal) => void;
    BodyComponent?: ComponentType<CommentProps>;
    resumeHash?: string;
    topicId?: string;
    vacancyId?: string;
}

const List: TranslatedComponent<ListProps> = ({
    trls,
    comments,
    fetch,
    user,
    onEdit,
    commentAction,
    editId,
    setEditId,
    fetchingIds,
    BodyComponent,
    resumeHash,
    topicId,
    vacancyId,
}) => {
    const isMagritte = useMagritte();

    const loadMore = useCallback(() => {
        fetch(comments.items.length);
    }, [fetch, comments]);

    if (comments.status === Status.Dirty) {
        return null;
    }

    if (comments.status === Status.Fetching && comments.items.length === 0) {
        return <Loading scale={LoadingScale.Small} />;
    }

    let more = null;
    if (comments.total > comments.items.length) {
        more = isMagritte ? (
            <>
                <VSpacing default={8} />
                <Link typography="paragraph-3-regular" data-qa="more-comments" onClick={loadMore} Element="button">
                    {trls[TrlKeys.showMore]}
                    {NON_BREAKING_SPACE}
                    <ConversionNumber
                        one={trls[TrlKeys.one]}
                        some={trls[TrlKeys.two]}
                        many={trls[TrlKeys.many]}
                        hasValue
                        value={Math.min(comments.maxOffset, comments.total - comments.items.length)}
                    />
                </Link>
            </>
        ) : (
            <div className="resume-sidebar-link">
                <BlokoLink
                    appearance={LinkAppearance.Pseudo}
                    kind={LinkKind.Tertiary}
                    data-qa="more-comments"
                    onClick={loadMore}
                    Element="button"
                >
                    {trls[TrlKeys.showMore]}
                    &nbsp;
                    <ConversionNumber
                        one={trls[TrlKeys.one]}
                        some={trls[TrlKeys.two]}
                        many={trls[TrlKeys.many]}
                        hasValue
                        value={Math.min(comments.maxOffset, comments.total - comments.items.length)}
                    />
                </BlokoLink>
            </div>
        );
    }

    return (
        <Fragment>
            {comments.items.map((item: ResumeComment) => (
                <Item
                    isFetching={fetchingIds.includes(item.id)}
                    editId={editId}
                    setEditId={setEditId}
                    onEdit={onEdit}
                    commentAction={commentAction}
                    user={user}
                    comment={item}
                    timeDiff={comments.timeDiff}
                    key={item.id}
                    BodyComponent={BodyComponent}
                    resumeHash={resumeHash}
                    topicId={topicId}
                    vacancyId={vacancyId}
                />
            ))}
            {more}
        </Fragment>
    );
};

export default memo(translation(List));
