import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AppStore } from 'src/app/store';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { ComplaintReasonTypeDoggyStyle, complaintReasonTypesAction } from 'src/models/complaintReasonTypes';
import { fetcher } from 'src/utils/fetcher';

const NEGOTIATION_COMPLAINT = '/shards/employer/negotiation_complaint';
declare global {
    interface FetcherGetApi {
        [NEGOTIATION_COMPLAINT]: {
            queryParams: { position: string; isLux: boolean };
            response: { reason: ComplaintReasonTypeDoggyStyle[] };
        };
    }
}

export default (
        addNotification: AddNotification,
        abortController: AbortController
    ): ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction> =>
    (dispatch: Dispatch<unknown>) => {
        return fetcher.get(NEGOTIATION_COMPLAINT, { signal: abortController.signal }).then(
            ({ reason }) => {
                if (abortController.signal.aborted) {
                    return;
                }
                dispatch(complaintReasonTypesAction(reason));
            },
            (err) => {
                if (fetcher.isCancel(err)) {
                    return;
                }
                defaultRequestErrorHandler(err, addNotification);
            }
        );
    };
