import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { Link, Text } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16, CrossOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import revokeAssessmentError from 'src/components/Notifications/RevokeAssessmentError';
import translation from 'src/components/translation';
import { Assessment, State, revoke } from 'src/models/assessments';
import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/employer/assessment/revoke': {
            body: {
                assessmentId: number;
            };
            queryParams: void;
            response: void;
        };
    }
}

const TrlKeys = {
    [State.Completed]: 'assessment.state.COMPLETED',
    [State.Deadline]: 'assessment.state.DEADLINE',
    [State.Expired]: 'assessment.state.EXPIRED',
    [State.New]: 'assessment.state.NEW',
    [State.Rejected]: 'assessment.state.REJECTED',
    [State.Revoked]: 'assessment.state.REVOKED',
    [State.Started]: 'assessment.state.STARTED',
    [`action${State.Completed}`]: 'assessment.state.action.COMPLETED',
    [`action${State.New}`]: 'assessment.state.action.NEW',
    [`action${State.WaitingForResult}`]: 'assessment.state.action.WAITING_FOR_RESULTS',
};

interface ResultProps {
    assessment: Assessment;
    revokeFromVacancyResponses?: ({ id }: { id: number }) => void;
    isResumeCard?: boolean;
}

const Result: TranslatedComponent<ResultProps> = ({ trls, assessment, revokeFromVacancyResponses, isResumeCard }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const revokeAssessmentLine = useMemo(
        () => revokeFromVacancyResponses || (({ id }: { id: number }) => dispatch(revoke({ id }))),
        [dispatch, revokeFromVacancyResponses]
    );

    const revokeAssessment = useCallback(() => {
        fetcher
            .postFormData('/employer/assessment/revoke', {
                assessmentId: assessment.assessmentId,
            })
            .then(() => {
                revokeAssessmentLine({ id: assessment.assessmentId });
            })
            .catch((e: AxiosError<{ state: State }>) => {
                addNotification(revokeAssessmentError, {
                    props: {
                        assessmentState: e.response?.data.state,
                        responseStatus: e.response?.status,
                    },
                });
            });
    }, [addNotification, assessment.assessmentId, revokeAssessmentLine]);

    switch (assessment.state) {
        case State.Assigned:
        case State.New:
            return (
                <Link
                    inline
                    typography="label-3-regular"
                    iconRight={<CrossOutlinedSize16 />}
                    onClick={revokeAssessment}
                    data-hh-assessment-id={assessment.assessmentId}
                    data-qa={`assessment-${assessment.code}-${assessment.state}`}
                >
                    {trls[TrlKeys[`action${State.New}`]]}
                </Link>
            );
        case State.Completed:
        case State.WaitingForResult:
            return (
                <Link
                    Element={SPALink}
                    to={`/employer/assessment/${assessment.assessmentId}`}
                    inline
                    typography="label-3-regular"
                    iconRight={<ChevronRightOutlinedSize16 />}
                    data-qa={`assessment-${assessment.code}-${assessment.state}`}
                >
                    {trls[TrlKeys[`action${assessment.state}`]]}
                </Link>
            );
        default:
            return (
                <Text
                    Element="span"
                    typography={isResumeCard ? 'label-3-regular' : 'label-4-regular'}
                    style="secondary"
                >
                    {trls[TrlKeys[assessment.state]]}
                </Text>
            );
    }
};

export default translation(Result);
