import { memo } from 'react';

import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormError, FormSpacer } from 'bloko/blocks/form';
import FormItem from 'bloko/blocks/form/FormItem';
import { H2, SubHeader } from 'bloko/blocks/header';
import { LoaderScaleSmall, IconColor } from 'bloko/blocks/icon';
import Radio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';

import { NonAppearanceReason, useNonAppearance } from 'src/components/Employer/NonAppearance/useNonAppearance';

const TrlKeys = {
    title: 'nonAppearanceModal.title',
    subtitle: 'nonAppearanceModal.subTitle',
    submit: 'nonAppearanceModal.submit',
    cancel: 'nonAppearanceModal.cancel',
    reasonRequired: 'nonAppearance.reasonRequired',
    reasons: {
        [NonAppearanceReason.ForceMajeure]: 'nonAppearanceReason.forceMajeure',
        [NonAppearanceReason.ChoseAnotherVacancy]: 'nonAppearanceReason.choseAnother',
        [NonAppearanceReason.Forgot]: 'nonAppearanceReason.forgot',
        [NonAppearanceReason.Other]: 'nonAppearanceReason.other',
    },
};
const NONAPPEARANCE_COMPLAINT = '/shards/employer/nonappearance_complaint';

interface BodyProps {
    vacancyId: string;
    resumeHash: string;
    reason: string | null;
}

declare global {
    interface FetcherPostApi {
        [NONAPPEARANCE_COMPLAINT]: {
            queryParams: void;
            body: BodyProps;
            response: void;
        };
    }
}

interface ContentProps {
    vacancyId: string;
    resumeHash: string;
    onCancel: () => void;
    onSubmit: () => void;
}

const Content: TranslatedComponent<ContentProps> = ({ vacancyId, resumeHash, onCancel, onSubmit, trls }) => {
    const { isValid, isLoading, onChangeReason, submitCallback, cancelCallback } = useNonAppearance({
        vacancyId,
        resumeHash,
        onCancel,
        onSubmit,
    });

    if (!vacancyId) {
        return null;
    }

    return (
        <Form className="non-appearance-form" onSubmit={submitCallback} data-qa="non-appearance-form">
            <H2 lite>
                {trls[TrlKeys.title]}
                <SubHeader>{trls[TrlKeys.subtitle]}</SubHeader>
            </H2>
            <VSpacing base={6} />
            <div>
                {Object.values(NonAppearanceReason).map((value) => (
                    <FormItem key={value}>
                        <Radio
                            name="reason"
                            onChange={() => {
                                onChangeReason(value);
                            }}
                            invalid={!isValid}
                            data-qa="non-appearance-form__reason"
                        >
                            {trls[TrlKeys.reasons[value]]}
                        </Radio>
                    </FormItem>
                ))}
                <FormError show={!isValid} data-qa="non-appearance-form__error">
                    {trls[TrlKeys.reasonRequired]}
                </FormError>
                <VSpacing base={8} />
                <Button kind={ButtonKind.Primary} type={ButtonType.Submit} data-qa="non-appearance-form__submit">
                    {trls[TrlKeys.submit]}
                </Button>

                <FormSpacer>
                    <Button onClick={cancelCallback} data-qa="non-appearance-form__cancel">
                        {trls[TrlKeys.cancel]}
                    </Button>
                </FormSpacer>
            </div>
            {isLoading && (
                <div className="non-appearance-form__overlay">
                    <LoaderScaleSmall initial={IconColor.Blue60} />
                </div>
            )}
        </Form>
    );
};

export default memo(translation(Content));
