import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import getContacts from 'src/api/resume/getContacts.obfuscate';
import { resumeUpdateContactPhonesAction } from 'src/models/resume';

export default ({ created, simHash, resumeHash, goal, event, topicId }, addNotification) =>
    (dispatch) => {
        return getContacts({ resumeHash, simHash, created, goal, event, topicId }).then(
            ({ data, status }) => {
                dispatch(resumeUpdateContactPhonesAction(data.phone));
                return { status };
            },
            (error) => {
                defaultRequestErrorHandler(error, addNotification);
                return { status: error.response?.status };
            }
        );
    };
