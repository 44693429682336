import { useMemo } from 'react';

import { useSelector } from 'src/hooks/useSelector';

const useGetResumeCommentsByUser = (userId: number) => {
    const commentsByUser = useSelector((store) => store.commentsByUserId?.[userId]);
    const resumeCommentsItems = useSelector(({ resumeComments }) => resumeComments.items);
    const resumeCommentsStatus = useSelector(({ resumeComments }) => resumeComments.status);

    return useMemo(
        () => ({
            items:
                commentsByUser?.items && resumeCommentsItems
                    ? commentsByUser.items.map((id) => resumeCommentsItems[id])
                    : [],
            total: commentsByUser?.total || 0,
            maxOffset: commentsByUser?.maxOffset || 0,
            status: resumeCommentsStatus,
        }),
        [commentsByUser, resumeCommentsItems, resumeCommentsStatus]
    );
};

export { useGetResumeCommentsByUser };
