import { useState, FormEvent } from 'react';

import { fetcher } from 'src/utils/fetcher';

const NONAPPEARANCE_COMPLAINT = '/shards/employer/nonappearance_complaint';

export enum NonAppearanceReason {
    ForceMajeure = 'FORCE_MAJEURE',
    ChoseAnotherVacancy = 'CHOSE_ANOTHER_VACANCY',
    Forgot = 'FORGOT',
    Other = 'OTHER',
}

interface BodyProps {
    vacancyId: string;
    resumeHash: string;
    reason: string | null;
}

declare global {
    interface FetcherPostApi {
        [NONAPPEARANCE_COMPLAINT]: {
            queryParams: void;
            body: BodyProps;
            response: void;
        };
    }
}

interface Props {
    vacancyId: string;
    resumeHash: string;
    onCancel: () => void;
    onSubmit: () => void;
}

interface ReturnType {
    reason: NonAppearanceReason | null;
    isValid: boolean;
    isLoading: boolean;
    onChangeReason: (reason: NonAppearanceReason) => void;
    submitCallback: (event: FormEvent) => void;
    cancelCallback: () => void;
}

const useNonAppearance = ({ vacancyId, resumeHash, onCancel, onSubmit }: Props): ReturnType => {
    const [reason, setReason] = useState<NonAppearanceReason | null>(null);
    const [isValid, setValid] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const sendComplaint = async (data: BodyProps) => {
        try {
            setLoading(true);
            await fetcher.post(NONAPPEARANCE_COMPLAINT, data);
        } finally {
            setLoading(false);
        }
    };

    const submitCallback = async (e: FormEvent) => {
        e.preventDefault();

        if (!reason) {
            setValid(false);
            return;
        }
        await sendComplaint({ vacancyId, resumeHash, reason });
        onSubmit();
    };

    const cancelCallback = () => {
        setValid(true);
        setReason(null);
        onCancel();
    };

    const onChangeReason = (newReason: NonAppearanceReason) => {
        setReason(newReason);
        setValid(true);
    };

    return {
        reason,
        isValid,
        isLoading,
        onChangeReason,
        submitCallback,
        cancelCallback,
    };
};

export { useNonAppearance };
