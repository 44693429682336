import { useState, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';

import { Text, Button, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import {
    sendBannerShown,
    sendBannerAnswer,
} from 'src/components/EmployerSearch/ContactFeedbackBanner/api/bannerActions';
import { BANNER_CLOSE_DELAY_MS } from 'src/components/EmployerSearch/ContactFeedbackBanner/consts';
import { Props, BannerShownData, BannerClickedData } from 'src/components/EmployerSearch/ContactFeedbackBanner/types';

import styles from './styles.less';

const TrlKeys = {
    defaultText: 'resume.contactFeedbackBanner.text.default',
    successText: 'resume.contactFeedbackBanner.text.success',
    buttonNo: 'resume.contactFeedbackBanner.button.no',
    buttonYes: 'resume.contactFeedbackBanner.button.yes',
};

/**
 * Баннер для сбора обратной связи получилось ли связаться с соискателем.
 */
const ContactFeedbackBanner: TranslatedComponent<Props> = ({
    resumeHash,
    vacancyId,
    topicId,
    paddingSize = 'medium',
    limitedWidth,
    trls,
}) => {
    const [shouldRender, setShouldRender] = useState(true);
    const [hasAnswer, setHasAnswer] = useState(false);
    const { isMobile } = useBreakpoint();
    const closeTimeoutRef = useRef<NodeJS.Timeout>();
    const employerId = useSelector((state) => state.employerId);
    const employerManagerId = useSelector((state) => state.employerManager?.id);

    const handleCloseWithTimeout = useCallback(() => {
        closeTimeoutRef.current = setTimeout(() => {
            setShouldRender(false);
        }, BANNER_CLOSE_DELAY_MS);
    }, [setShouldRender]);

    const handleAnswer = useCallback(
        (answer: boolean) => {
            const params: BannerClickedData = {
                employerManagerId: Number(employerManagerId),
                employerId: Number(employerId),
                resumeHash,
                successContact: answer,
                topicId: Number(topicId),
                vacancyId,
            };

            sendBannerAnswer(params);
            setHasAnswer(true);
            handleCloseWithTimeout();
        },
        [employerManagerId, employerId, resumeHash, topicId, vacancyId, setHasAnswer, handleCloseWithTimeout]
    );

    const handleNegativeAnswer = useCallback(() => {
        handleAnswer(false);
    }, [handleAnswer]);

    const handlePositiveAnswer = useCallback(() => {
        handleAnswer(true);
    }, [handleAnswer]);

    useEffect(() => {
        const params: BannerShownData = {
            employerManagerId: Number(employerManagerId),
            employerId: Number(employerId),
            resumeHash,
            topicId: Number(topicId),
            vacancyId,
        };

        const abortController = new AbortController();
        sendBannerShown(params, abortController.signal);

        return () => {
            abortController.abort();
        };
    }, [employerManagerId, employerId, resumeHash, topicId, vacancyId]);

    if (!shouldRender) {
        return null;
    }

    return (
        <>
            {paddingSize === 'small' && <VSpacing default={12} />}
            {paddingSize === 'medium' && <VSpacing default={12} gteM={24} />}
            {paddingSize === 'large' && <VSpacing default={16} gteM={32} />}
            <div
                className={classnames(styles.banner, {
                    [styles.limitedWidth]: limitedWidth,
                })}
                data-qa="contact-feedback-banner"
            >
                <Text
                    typography={isMobile ? 'label-3-regular' : 'label-2-regular'}
                    style="primary"
                    data-qa="contact-feedback-banner-text"
                >
                    {trls[TrlKeys[!hasAnswer ? 'defaultText' : 'successText']]}
                </Text>
                {!hasAnswer && (
                    <div className={styles.buttons}>
                        <div className={styles.buttonWrapper}>
                            <Button
                                size="small"
                                style="neutral"
                                mode="primary"
                                data-qa="answer-no-button"
                                onClick={handleNegativeAnswer}
                                stretched
                            >
                                {trls[TrlKeys.buttonNo]}
                            </Button>
                        </div>
                        <div className={styles.buttonWrapper}>
                            <Button
                                size="small"
                                style="neutral"
                                mode="primary"
                                data-qa="answer-yes-button"
                                onClick={handlePositiveAnswer}
                                stretched
                            >
                                {trls[TrlKeys.buttonYes]}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {paddingSize === 'large' && <VSpacing default={16} gteM={16} />}
        </>
    );
};

export default translation(ContactFeedbackBanner);
