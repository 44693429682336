import { useMemo } from 'react';

import { GroupedMessages, MessageItem } from 'src/models/employer/resume/pinnedChatMessages';

const useGroupedMessages = (pinnedChatMessages: MessageItem[]): GroupedMessages[] => {
    return useMemo(
        () =>
            pinnedChatMessages.reduce((result: GroupedMessages[], message: MessageItem) => {
                const lastGroup = result[result.length - 1];
                if (!lastGroup) {
                    return [{ participantType: message.participant.type, messages: [message] }];
                }
                if (lastGroup.participantType === message.participant.type) {
                    return [...result.slice(0, -1), { ...lastGroup, messages: [...lastGroup.messages, message] }];
                }
                return [...result, { participantType: message.participant.type, messages: [message] }];
            }, [] as GroupedMessages[]),
        [pinnedChatMessages]
    );
};

export { useGroupedMessages };
