import { useRef } from 'react';

import { Drop, Button, ButtonMode, ButtonSize, BottomSheet, DropProps, ButtonStyle } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChangeTopicMenuItem, { SendAnalyticsHandler } from 'src/components/ChangeTopicMenuItem';
import translation from 'src/components/translation';
import { useToggleState } from 'src/hooks/useToggleState';
import { NegotiationLink } from 'src/models/employerNegotiations/negotiationLinks';

import styles from './styles.less';

const TrlKeys = {
    buttonText: 'vacancyResponse.discard',
    shortButtonText: 'vacancyResponse.discard.short',
};

interface ChangeTopicButtonProps {
    host?: DropProps['host'];
    mode?: ButtonMode;
    size?: ButtonSize;
    links: NegotiationLink[];
    showIcon?: boolean;
    showShortText?: boolean;
    stretched?: boolean;
    style?: ButtonStyle;
    sendAnalytics?: SendAnalyticsHandler;
    dataQa?: string;
}

const ChangeTopicButton: TranslatedComponent<ChangeTopicButtonProps> = ({
    host,
    mode = 'primary',
    size = 'medium',
    style,
    links,
    showIcon,
    showShortText,
    stretched,
    sendAnalytics,
    dataQa,
    trls,
}) => {
    const [isMenuVisible, toggleMenuVisible, setMenuVisible] = useToggleState(false);
    const menuActivatorRef = useRef(null);
    const menuActivatorProps = {
        ref: menuActivatorRef,
        stretched,
        mode,
        size,
        style,
        onClick: toggleMenuVisible,
        'data-qa': dataQa,
    };

    const content = links.map(({ id, state, text, url }) => (
        <ChangeTopicMenuItem key={id} state={state} name={text} url={url} sendAnalytics={sendAnalytics} isMagritte />
    ));
    const activatorText = trls[showShortText ? TrlKeys.shortButtonText : TrlKeys.buttonText];

    return (
        <>
            <Drop
                host={host}
                visible={isMenuVisible}
                onClose={() => setMenuVisible(false)}
                activatorRef={menuActivatorRef}
                role="status"
                placement="bottom-left"
                space={0}
            >
                <div className={styles.drop}>{content}</div>
            </Drop>
            <BottomSheet visible={isMenuVisible} onClose={() => setMenuVisible(false)}>
                {content}
            </BottomSheet>
            {showIcon ? (
                <Button
                    icon={<ChevronDownOutlinedSize16 />}
                    aria-label={trls[TrlKeys.buttonText]}
                    {...menuActivatorProps}
                >
                    {activatorText}
                </Button>
            ) : (
                <Button {...menuActivatorProps}>{activatorText}</Button>
            )}
        </>
    );
};

export default translation(ChangeTopicButton);
export type { SendAnalyticsHandler };
