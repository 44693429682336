import { CommentsOwner } from 'src/components/ResumeComments/types';
import { ResumeCommentsList } from 'src/models/employer/resume/resumeComments';
import { fetcher } from 'src/utils/fetcher';

const COMMENTS_ON_PAGE = 3;

const COMMENTS_URL = '/employer/applicant/json_comment';

declare global {
    interface FetcherGetApi {
        [COMMENTS_URL]: {
            response: ResumeCommentsList;

            queryParams: { applicantId: CommentsOwner; print: boolean; offset: number; limit: number };
        };
    }
}

interface FetchOffsetParams {
    applicantId: number;
    limit: number | null;
    offset: number;
    print: boolean;
    signal?: AbortSignal;
}

const fetchComments = ({ applicantId, limit, offset, print, signal }: FetchOffsetParams) =>
    fetcher.get(COMMENTS_URL, {
        params: {
            applicantId,
            print,
            offset,
            limit: limit || COMMENTS_ON_PAGE,
        },
        signal,
    });

export default fetchComments;
