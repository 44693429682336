import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { AppDispatch } from 'src/models/appDispatch';
import { FoldersInvariants } from 'src/models/resumeFolders';
import { fetcher } from 'src/utils/fetcher';

const resumesFoldersAction = makeSetStoreField('resumeFolders');

declare global {
    interface FetcherPostApi {
        '/employer/resumefolders/putinfolder': {
            queryParams: {
                resumeHash: string[];
                target: Array<'add' | 'remove'>;
            };
            body: {
                resumeHash: string;
                folderId?: string[];
                simhash?: string;
                employerManagerId: number;
            };
            response: void;
        };
    }
}

export interface PutResumesFolderRequestArgs {
    foldersInvariants: FoldersInvariants;
    dispatch: AppDispatch;
    checkedFolders?: string[];
}

export type PutResumesFolderRequest = (args: PutResumesFolderRequestArgs) => Promise<void>;

export default (
        {
            simHash,
            employerManagerId,
            onSuccess,
            resumeHash,
        }: {
            simHash?: string;
            employerManagerId: number;
            onSuccess?: (hasSelected: boolean) => void;
            resumeHash: string;
        },
        addNotification: AddNotification
    ) =>
    ({ foldersInvariants, dispatch, checkedFolders }: PutResumesFolderRequestArgs): Promise<void> =>
        fetcher
            .postFormData(
                '/employer/resumefolders/putinfolder',
                {
                    resumeHash,
                    folderId: checkedFolders,
                    simhash: simHash,
                    employerManagerId,
                },
                {
                    params: {
                        resumeHash: [resumeHash],
                        target: checkedFolders?.length ? ['add'] : ['remove'],
                    },
                }
            )
            .then(() => {
                onSuccess?.(!!checkedFolders?.length);
                dispatch(
                    resumesFoldersAction({
                        foldersInvariants,
                        foldersByResume: { [resumeHash]: [...(checkedFolders || [])] },
                    })
                );
            })
            .catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            });
