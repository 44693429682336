import { memo, useRef } from 'react';

import { Action, HSpacingContainer, Loader, TooltipHover } from '@hh.ru/magritte-ui';
import { TrashOutlinedSize16, PenOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    change: 'employer.resume.comment.change',
    delete: 'resume.comment.delete',
    restore: 'employer.resume.comment.restore',
    deleted: 'employer.resume.comment.deleted',
};

interface ControlsProps {
    editable: boolean;
    onToggleEdit: () => void;
    onToggleRemove: () => void;
    isFetching: boolean;
}

const Controls: TranslatedComponent<ControlsProps> = ({ trls, editable, onToggleEdit, onToggleRemove, isFetching }) => {
    const editActionRef = useRef<HTMLButtonElement>(null);
    const removeActionRef = useRef<HTMLButtonElement>(null);

    if (!editable) {
        return null;
    }

    if (isFetching) {
        return (
            <div className="resume-sidebar-item__info resume-sidebar-item__info_noprint">
                <Loader />
            </div>
        );
    }

    return (
        <div className="resume-sidebar-item__info resume-sidebar-item__info_noprint">
            <HSpacingContainer default={8}>
                <Action icon={PenOutlinedSize16} onClick={onToggleEdit} data-qa="comment__edit" ref={editActionRef} />
                <TooltipHover placement="top-center" activatorRef={editActionRef}>
                    {trls[TrlKeys.change]}
                </TooltipHover>
                <Action
                    icon={TrashOutlinedSize16}
                    onClick={onToggleRemove}
                    data-qa="comment__delete"
                    ref={removeActionRef}
                />
                <TooltipHover placement="top-center" activatorRef={removeActionRef}>
                    {trls[TrlKeys.delete]}
                </TooltipHover>
            </HSpacingContainer>
        </div>
    );
};

export default memo(translation(Controls));
