import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import fetchComments from 'src/components/ResumeComments/api/fetchComments';
import { useSelector } from 'src/hooks/useSelector';
import { addBatchCommentsToUser } from 'src/models/employer/resume/commentsByUser';
import { loadComments, startFetch, Status } from 'src/models/employer/resume/resumeComments';

import { useGetResumeCommentsByUser } from 'src/components/ResumeComments/hooks/useGetResumeCommentsByUser';

type UseFetchCommentsHook = (
    user: number,
    noCommentsCallback?: () => void
) => {
    fetch: (offset: number, signal?: AbortSignal) => void;
    comments: ReturnType<typeof useGetResumeCommentsByUser>;
};

const useFetchComments: UseFetchCommentsHook = (user, noCommentsCallback) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const resumeCommentsLimit = useSelector(({ resumeCommentsLimit }) => resumeCommentsLimit);
    const print = useSelector((state) => !!state.printVersion.comments);
    const comments = useGetResumeCommentsByUser(user);

    const fetch = useCallback(
        (offset: number, signal?: AbortSignal) => {
            dispatch(startFetch());
            fetchComments({ offset, limit: resumeCommentsLimit, applicantId: user, print, signal })
                .then((res) => {
                    if (res.total === 0) {
                        noCommentsCallback?.();
                    }
                    dispatch([
                        loadComments(res),
                        addBatchCommentsToUser({
                            userId: user,
                            commentIds: res.data.map(({ id }) => id),
                            total: res.total,
                            maxOffset: res.maxOffset,
                        }),
                    ]);
                })
                .catch((e) => {
                    defaultRequestErrorHandler(e, addNotification);
                });
        },
        [dispatch, user, print, resumeCommentsLimit, noCommentsCallback, addNotification]
    );

    useEffect(() => {
        const abortController = new AbortController();
        if (comments.status === Status.Initial) {
            fetch(0, abortController.signal);
        }

        return () => {
            if (comments.status === Status.Fetching) {
                abortController.abort();
            }
        };
    }, [comments, fetch]);

    return { comments, fetch };
};

export { useFetchComments };
