import { useCallback } from 'react';
import { FormApi } from 'final-form';

import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import { ResumeShareFormData } from 'src/components/HiringManager/types';
import { useMarkAsRead } from 'src/components/VacancyResponses/api/useMarkAsRead';
import { useSelector } from 'src/hooks/useSelector';
import { VacanciesInfo } from 'src/models/candidatesList';
import { ManagerType } from 'src/models/employerManagers.types';
import { ManagersList } from 'src/models/employerManagersPage';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';
import { fetcher } from 'src/utils/fetcher';
import { UXFeedback } from 'src/utils/uxfeedback';

const PUT_RESUME_SHARE_URL = '/shards/employer/hiring_managers/share_resume';

export type ShareResumePayload =
    | {
          resumeHash: string;
          employerManagerId: string;
          userId: string;
          vacancyId?: string;
      }
    | {
          resumeHash: string;
          employerManagerId: string;
          userId: string;
          vacancyId?: string;
          permissions: VacancyPermission[];
      };

declare global {
    interface FetcherPutApi {
        [PUT_RESUME_SHARE_URL]: {
            queryParams: void;
            body: {
                items: ShareResumePayload[];
                vacancyId?: string;
            };
            response: void;
        };
    }
}

interface UseShareResumeParams {
    selectedManagers: ManagersList[];
    resumeHash: string[];
    onClose: () => void;
    vacanciesInfo?: VacanciesInfo;
    topicIds?: string[];
}

type UseShareResumeHook = (
    params: UseShareResumeParams
) => (data: ResumeShareFormData, form: FormApi<ResumeShareFormData>) => Promise<void>;

export const useShareResume: UseShareResumeHook = ({
    selectedManagers,
    onClose,
    resumeHash,
    topicIds,
    vacanciesInfo,
}) => {
    const isNotHiringManager = useSelector((state) => !state.isHiringManager);
    const showNotification = useHiringManagerNotification();
    const markAsRead = useMarkAsRead();
    return useCallback(
        async (data: ResumeShareFormData, form: FormApi<ResumeShareFormData>) => {
            try {
                const vacancyName = vacanciesInfo?.name;
                await fetcher.put(PUT_RESUME_SHARE_URL, {
                    items: resumeHash.reduce((acc, hash) => {
                        return acc.concat(
                            selectedManagers.map((manager) => ({
                                resumeHash: hash,
                                userId: manager.userId.toString(),
                                employerManagerId: manager.id.toString(),
                                vacancyId: vacanciesInfo?.vacancyId.toString(),
                                ...(data.configureVacancyPermissions &&
                                    vacanciesInfo?.vacancyId && {
                                        permissions:
                                            manager.managerType === ManagerType.McpGroup ||
                                            manager.type === ManagerType.Mcp
                                                ? [
                                                      VacancyPermission.ViewContacts,
                                                      VacancyPermission.ViewDesirableCompensation,
                                                  ]
                                                : [
                                                      data[VacancyPermission.ViewDesirableCompensation] &&
                                                          VacancyPermission.ViewDesirableCompensation,
                                                      data[VacancyPermission.ViewContacts] &&
                                                          VacancyPermission.ViewContacts,
                                                  ].filter<VacancyPermission>(
                                                      (
                                                          permission: VacancyPermission | boolean
                                                      ): permission is VacancyPermission => !!permission
                                                  ),
                                    }),
                            }))
                        );
                    }, [] as ShareResumePayload[]),
                    vacancyId: data.configureVacancyPermissions ? vacanciesInfo?.vacancyId.toString() : undefined,
                });
                if (vacanciesInfo?.vacancyId && topicIds?.length) {
                    await markAsRead({ vacancyId: vacanciesInfo.vacancyId.toString(), topicIds });
                }
                form.reset();
                onClose();
                showNotification?.(
                    vacancyName ? [vacancyName] : undefined,
                    data.configureVacancyPermissions && vacanciesInfo
                        ? HiringManagerNotificationType.VacancyShared
                        : HiringManagerNotificationType.ResumeShared
                );
                if (isNotHiringManager && !data.configureVacancyPermissions) {
                    UXFeedback.sendEvent('share_resume_to_hiring_manager');
                } else if (isNotHiringManager) {
                    UXFeedback.sendEvent('share_vacancy_to_hiring_manager');
                }
            } catch (_) {
                showNotification?.(
                    vacanciesInfo?.name ? [vacanciesInfo.name] : undefined,
                    HiringManagerNotificationType.DefaultError
                );
            }
        },
        [
            isNotHiringManager,
            markAsRead,
            onClose,
            resumeHash,
            selectedManagers,
            showNotification,
            topicIds,
            vacanciesInfo,
        ]
    );
};
