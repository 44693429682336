import { Snackbar } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, TrashOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    resumeCommentAdd: 'resume.comments.notification.add.title',
    resumeCommentEdit: 'resume.comments.notification.edit.title',
    resumeCommentDeleted: {
        deleted: 'employer.resume.comment.deleted',
        restore: 'employer.resume.comment.restore',
    },
};

const Edit: TranslatedComponent<{ removeNotification: () => void }> = ({ trls, removeNotification }) => (
    <Snackbar
        data-qa="resume_comment_edit"
        onClose={removeNotification}
        showClose
        addon={<CheckCircleFilledSize24 initialColor="positive" />}
    >
        {trls[TrlKeys.resumeCommentEdit]}
    </Snackbar>
);

export const ResumeCommentEdit = translation(Edit);

const Add: TranslatedComponent<{ removeNotification: () => void }> = ({ trls, removeNotification }) => (
    <Snackbar
        data-qa="resume_comment_add"
        showClose
        onClose={removeNotification}
        addon={<CheckCircleFilledSize24 initialColor="positive" />}
    >
        {trls[TrlKeys.resumeCommentAdd]}
    </Snackbar>
);
export const ResumeCommentAdd = translation(Add);

const Delete: TranslatedComponent<{
    restoreComment: () => void;
    removeNotification: () => void;
}> = ({ trls, removeNotification, restoreComment }) => (
    <Snackbar
        data-qa="resume_comment_delete"
        showClose
        actionLabel={trls[TrlKeys.resumeCommentDeleted.restore]}
        onAction={() => {
            restoreComment();
            removeNotification();
        }}
        onClose={removeNotification}
        addon={<TrashOutlinedSize24 initialColor="contrast" />}
    >
        {trls[TrlKeys.resumeCommentDeleted.deleted]}
    </Snackbar>
);

export const ResumeCommentDeleteMessage = translation(Delete);
